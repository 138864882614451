.video-wrapper {
  width:100%;
  
}

.video-wrapper:hover .playpause {
   transform: scale(1.1);
   cursor: pointer;
  }

.playpause {
    background-image:url(./../images/play.svg);
    background-repeat:no-repeat;
    transition: all ease 0.1s;
    width:3rem;
    height:3rem;
    position:absolute;
    left:0%;
    right:0%;
    top:0%;
    bottom:0%;
    margin:auto;
    background-size:contain;
    background-position: center;
}