.regulamin{
    background:white;
    position: relative;
    z-index: 111;
    margin-top:120px;
    padding-top:2rem;

    @include breakpoint($md){
      margin-top:120px;
    }


    ol {
        list-style-type: none;
        counter-reset: item;
        margin: 0;
        padding: 0;
      }
      
      ol > li {
        display: table;
        counter-increment: item;
        margin-bottom: 1em;
        font-size:1.1rem;
        font-weight: bold;
      }
      ol > li:before {
        content: counters(item, ".") ". ";
      
        padding-right:0.4em;   
        @include breakpoint($md){
          display: table-cell;
        }
      }

     ol ol > li:before {
        content: counters(item, ".") ". ";
        display: table-cell;
        width: 2.5em; 
        text-align: right;  
        padding-right:0.6rem; 
      }
      
      li ol > li {
        margin: 0;
        margin-bottom: 0.6em;
        font-size:1rem;
        font-weight:normal;
      }
      li ol > li:before {
        content: counters(item, ".") " ";
      }
      ol ol{
        margin-top: 0.6em;
      }
      ol ul{
        list-style-type:disc;
        margin-top:0.6em;
      }
      ol ul li{
        margin-bottom: 0.6em;
      }
      ol ul ul{
        list-style-type:circle;
        margin-left:1.3em;
        margin-bottom:0.6em;
      }
      
      

}