.order-status-wrapper{
    display:flex;
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content:center;
    width:100vw;
    padding-bottom: 2rem;



}
.order-status{
    box-shadow: 0 0 12px rgba(0,0,0,0.2);
    border-radius:10px;
    background: white;
    padding:20px;
    margin:1rem 2rem 0 2rem;
    max-width:380px;
    display: flex;
    flex-direction: row;
    flex-wrap:wrap;
    align-items: flex-start;
    position: relative;



    &__message{
        display:flex;
        align-items: center;
        margin-bottom:0.8rem;
    
    }
    &__success-ico{
        fill:rgb(44, 193, 44);
        width: 40px;
        margin-right:0.3rem;   
    }
    &__success-ico + p{
        font-size: 0.9rem;
        color:rgb(44, 193, 44);
    }

    &__error-ico{
        fill:rgb(191, 10, 28);
        width: 40px;
        margin-right:0.3rem;   
    }
    &__error-ico + p{
        font-size: 0.9rem;
       color:rgb(191, 10, 28);
    }

    &__header{
        line-height: 1.1em;
        margin-bottom:1rem;
        font-size: 1.9rem;
    }

    a{
        color: #999;

    }

    a:hover{
        color: #333;
        
    }



}