
.contact{

    padding-top:7rem;

    @include breakpoint($md){
        padding-top:6rem;
    }

    &__address{
        font-size: 2.1rem;
        line-height: 1.5em;
        font-style: normal;
        @include breakpoint($md){
            font-size: 1.8rem;
        }
        @include breakpoint($lg){
            font-size: 1.6rem;
        }
        @include breakpoint($xl){
           
        }
        small  {
            font-size: 1rem;
        }
         
    }

    &__person{
        p{
            font-size: 1.7rem;
            line-height: 1.5em;
            @include breakpoint($md){ 
                font-size: 1.2rem;
            }
            @include breakpoint($lg){ 
                font-size: 1.2rem;
            }
        }
        small{
            font-size: 0.95rem;
         
            @include breakpoint($lg){ 
                font-size: 0.65rem;
            }
        }
        
        @include breakpoint($lg){
      
        }
        @include breakpoint($xl){

        }
        
    }
}
    