@charset 'UTF-8';


//Ręcznie dodane
@import 'bower_components/swiper/src/swiper.scss';



.las{
  padding:1000px;
}
@import "breakpoints";
@import "colors";
@import "typography"; 
@import "wyswig_content"; 

// @import "spacing";
// @import "sizes";
@import "base"; 
 
// @import "other"; 




@import "layouts/main-layout";
@import "layouts/home-page";
@import "layouts/home-page_kolory";
@import "layouts/oferta";
@import "layouts/projekty";
@import "layouts/about";
@import "layouts/contact";
@import "layouts/footer";
@import "layouts/case";
@import "layouts/regulamin";
@import "layouts/faq";
@import "layouts/przydatne_kody";
@import "layouts/prace";
@import "layouts/praca";
@import "layouts/order-status";
@import "layouts/static-content";

@import "modules/top-nav";
@import "modules/video";
@import "modules/modal";
@import "modules/form";
@import "modules/form-sklep";


// .content{
//   @import "modules/content-styles";
// }

// @import "modules/integrator-cta";

// @import "modules/left-nav";
// @import "modules/slider";
// @import "modules/footer";
// @import "modules/golf-baner";



// @import "galeria";

// @import "playground";