/* purgecss start ignore */
.wyswig-content{
	
	strong{
		color:#0030FF !important;
		font-weight: 500;
	}
	p{
		margin-bottom:1.3em;
		font-size: 1.1rem;
	}
	ul li{
		list-style-type:disc;
		margin-bottom:1em;
		margin-left: 1em;
		font-size: 1.1rem;
	}
	ol li{
		list-style-type:decimal;
		margin-bottom:1em;
		margin-left: 1em;
		font-size: 1.1rem;
	
	}
	h2{
		font-size: 1.8rem;
		margin-bottom:1.3em;
	}
	h3{
		font-size: 1.4rem;
		margin-bottom:1.3em;
	}
	h4{
		font-size: 1.2rem;
		margin-bottom:1.3em;
	}
	&__blue-text{
		color:#0030FF;
	}
}
/* purgecss end ignore */