
   /*
   ███████╗ ██████╗ ██████╗ ███╗   ███╗██╗   ██╗██╗      █████╗ ██████╗ ███████╗    ███████╗██╗  ██╗██╗     ███████╗██████╗ 
   ██╔════╝██╔═══██╗██╔══██╗████╗ ████║██║   ██║██║     ██╔══██╗██╔══██╗╚══███╔╝    ██╔════╝██║ ██╔╝██║     ██╔════╝██╔══██╗
   █████╗  ██║   ██║██████╔╝██╔████╔██║██║   ██║██║     ███████║██████╔╝  ███╔╝     ███████╗█████╔╝ ██║     █████╗  ██████╔╝
   ██╔══╝  ██║   ██║██╔══██╗██║╚██╔╝██║██║   ██║██║     ██╔══██║██╔══██╗ ███╔╝      ╚════██║██╔═██╗ ██║     ██╔══╝  ██╔═══╝ 
   ██║     ╚██████╔╝██║  ██║██║ ╚═╝ ██║╚██████╔╝███████╗██║  ██║██║  ██║███████╗    ███████║██║  ██╗███████╗███████╗██║     
   ╚═╝      ╚═════╝ ╚═╝  ╚═╝╚═╝     ╚═╝ ╚═════╝ ╚══════╝╚═╝  ╚═╝╚═╝  ╚═╝╚══════╝    ╚══════╝╚═╝  ╚═╝╚══════╝╚══════╝╚═╝     
                                                                                                                            
   */

   /* purgecss start ignore */


    
    .formularz-zgloszeniowy{
      background:white;
      position: relative;
      z-index: 111;
      margin-top:120px;
      padding-top:2rem;
      @include breakpoint($md){
        margin-top:120px;
      }
    }
    


  $input-bg : #f5f5f5;
  $focus-color: $black;


.formularz-sklep{
  display: flex;
  flex-wrap: wrap;

  & > div{
    width:100%;
  }

    button,
    input[type=reset],
    input[type=button],
    input[type=submit],
    input[type=checkbox],
    input[type=radio],
    select
    {
        -webkit-box-sizing:border-box;
        -moz-box-sizing:border-box;
        box-sizing:border-box;
        cursor: pointer;
    }


    select:focus,
    input:focus
    {
        outline:0;
    }

        button, fieldset{
      width:100%;
    }

    &__pickup{

        display: flex;
        margin-bottom: 36px;
        overflow: hidden;
        width: 100%;
        align-items: flex-start;
      input {
        position: absolute !important;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        width: 1px;
        border: 0;
        overflow: hidden;
        margin-bottom: 0;
      }
      & > div{
        flex-grow: 1;
      }
      label {
        // background-color: #e4e4e4;
        color: rgba(0, 0, 0, 0.6);
        margin-bottom: 0 !important;
        line-height: 1;
        text-align: center;
        padding: 20px 0;
        flex-grow: 1;
        font-size:1rem;
        border: 2px solid #ccc;
        // box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
        transition: all 0.1s ease-in-out;
        border-radius: 4px 4px 4px 4px;
      }
      label + div {
        flex-grow: 1;
        margin-right:0;
        
      }
      label.smaller {
        font-size: 0.8em;
      }
      label:hover {
        cursor: pointer;
      }
      
      input:checked + label {
        // background-color: #a5dc86;
        box-shadow: none;
        border:2px solid $focus-color;
        color:$focus-color;

      }

  
      fieldset > div, .smaller:first-of-type, & > div:first-of-type {
        
        margin-right:0.7rem;
      }

      &__info{
        font-size:0.6em;
        margin-top:-0.2rem;
        display: inline-block;
      }

      .first-in-order{
        order:-1;
        margin-right: 0.7rem;
      }
      .first-in-order + div{
        margin-right: 0;
      }

    }


 
    &__pickup2{

      display: flex;
      margin-bottom: 36px;
      overflow: hidden;
      width: 100%;
    
    input {
      position: absolute !important;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      width: 1px;
      border: 0;
      overflow: hidden;
    }
    
    label {
      background-color: #e4e4e4;
      color: rgba(0, 0, 0, 0.6);
      font-size: 14px;
      line-height: 1;
      text-align: center;
      padding: 8px 16px;
      margin-right: -1px;
      border: 1px solid rgba(0, 0, 0, 0.2);
      box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
      transition: all 0.1s ease-in-out;
    }
    
    label:hover {
      cursor: pointer;
    }
    
    input:checked + label {
      background-color: #a5dc86;
      box-shadow: none;
    }
    
    label:first-of-type {
      border-radius: 4px 0 0 4px;
    }
    
    label:last-of-type {
      border-radius: 0 4px 4px 0;
    }
    
  }

  &__input-group{
    display: flex;
    flex-direction: column;
    width:100%;
    margin-bottom:0;
    position: relative;
  }
  &__input-search{
    padding-left:2.2em !important;
  }
  &__input-search-ico{
    position:absolute;
    top:0.7em;
    left:0.7em;
    fill:#aaa;
    width: 30px;
  }
  &__label-dopisek{
    font-size: 0.6rem;
    margin-bottom: 0.3rem;
    color: $black-lighter;
    margin-left:1em;
  }

	label{
        font-size: 0.9rem;
        margin-bottom: 0.6em;
       
        display: block;
       span{
        font-size:0.75em;
       }

    }

   
    input:-webkit-autofill,
    input:-webkit-autofill:focus {
      transition: background-color 600000s 0s, color 600000s 0s;
    }
    
    select{
      width: 100%;
    }

    textarea{
      height: auto;
      font-size: 0.9em;
      padding: 1rem 1rem;
    }

    small{
      color:red;
      transform: translateY(-1.2rem);
     
      font-size:0.7rem;
      display: block;
      width: 100%;
     
  }

    &__student{
      margin-top:-1.8rem;
    }
  
      input:hover, textarea:hover {
        border: 2px solid #ccc;
        cursor: pointer;
      }

      input:focus, input:active, textarea:focus, textarea:active {
        border: 2px solid $focus-color;
        outline: none;
      }
      input[type=checkbox], input[type=radio]{
        width:17px;
        height:17px;
        padding:0;
        border: 2px solid #ccc;
        vertical-align: middle;
        margin-right:0.4rem;
        margin-bottom: 0;
        background:#fff;
      }
      input[type=checkbox]:focus, input[type=radio]:focus {
        box-shadow: 0 0 2px #999;
        outline: 0;
      }

      input[type='checkbox']:after, input[type='radio']:after {
        content: '';
        display: block;
        position: absolute;
        top: 0; 
        left: 0;
        
      }
      input[type='checkbox']:checked,
      input[type='radio']:checked {
        background: $focus-color;
        border: 2px solid $focus-color, 2px solid #fff;
       
       
      }

      input[type=file]{
        border: 0;
        margin-left: 0;
        padding-left:0;
        background:  $input-bg;
        padding:1.5%;
        height:auto;
      }

      input::file-selector-button, input::-webkit-file-upload-button {
        font-weight: bold;
       
        padding: 0.5em 1em;
        margin-left:0;
        border:0;
        font-weight: normal;
        margin-right:0.9rem;
        background: #333;
        color: white;
        cursor: pointer;
        transition: all 200ms ease;
    }

    input::file-selector-button:hover, input::-webkit-file-upload-button:hover {
      margin-left:0;
      background: $focus-color; 
  }


  input,textarea{
        
    background:$input-bg;
    padding: 0.7em 0.7em;
    font-weight: 500;
    transition: all 200ms ease;
    border: 2px solid #ccc;
    border-radius: 5px;
  background: $input-bg;
    margin-bottom:1.5rem;
    -webkit-appearance: none;
  
    font-size:1.1rem;
    font-family: $font-1;
    
}



    
    select{
        
        width:100%;
        padding: 0.7em 0.7em;
        transition: all 200ms ease;
        font-weight: 500;
        border: 2px solid #fff;
       
       
        font-family: $font-1;
        -webkit-appearance: none;
        -webkit-border-radius:0; 
        border-radius:0;
        background:  $input-bg;
        margin-bottom:2.5rem;
        /* SVG background image */
        background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%3Ctitle%3Edown-arrow%3C%2Ftitle%3E%3Cg%20fill%3D%22%23000000%22%3E%3Cpath%20d%3D%22M10.293%2C3.293%2C6%2C7.586%2C1.707%2C3.293A1%2C1%2C0%2C0%2C0%2C.293%2C4.707l5%2C5a1%2C1%2C0%2C0%2C0%2C1.414%2C0l5-5a1%2C1%2C0%2C1%2C0-1.414-1.414Z%22%20fill%3D%22%23000000%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E");
        background-size: .8em;
        background-position: calc(100% - 1.1em) center;
        background-repeat: no-repeat;
        font-size:1.1rem;
    }

    option{
      font-size:0.3rem;
    }

    select::-ms-expand {
        display: none;
    }

    select:hover {
        border: 2px solid #ccc;
      }
      select:active {
        border: 2px solid #fff;
      }
      select[multiple] option {
        font-size:11px !important;
      }

      select:after {
        font-size: 1.1rem;
        background: red;
      }

      button{
          background: #333;
          color:white;
          padding:15px 45px 15px 45px;
          margin-top:1rem;
          transition: all 200ms ease;
          border:none;
          font-size:1.2rem;
          margin-bottom:0;
      }
      button:hover{
        background: $focus-color;
      }

      .success{
          color:success;
      }


      &__zgody{
          font-size: 11px;
          display: flex;
         
          width: 100%;
          margin-bottom:1.1rem;
          
          input{
              flex-shrink: 0;
          }
          small{
            transform: none;
          }
          
     

          span{
            margin-top:0.3rem;
            margin-bottom:0.4rem;
            display: block;
          }
      }

      &__total{
       
      }
     

}

// .for-checkbox{
//   padding-top:1rem;
//   display: inline-block;
//   transform: initial;
//   width:100%;
// }

#inpost-geowidget, iframe{
  width:90vw !important;
  height:calc(100vh - 10vw)  !important;
  margin:5vw 5vw;
}







#wgladowka-prev img{
  margin-top:-1rem;
  margin-bottom:3rem;
}

/* Slide share responsive */


/* purgecss end ignore */


.side-box{
  
margin-bottom:2rem;
margin-top:0.5rem;
padding: 1.5rem 1.5rem;
border: 1px solid #ccc;

  @include breakpoint($md){
   
  }

  &__header{
    font-size: 1rem;
    margin-bottom: 1rem;
    margin-top: 0 !important;
  }
  li{
    list-style:none;
    margin-bottom:0.3rem;
  }
  a{
    font-size: 0.8rem;
    
  }
}