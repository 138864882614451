
.sity-logo{

    color: white;
    text-align: center;
    font-size:1.4rem;
    margin-top:3rem;
}

$gray-text: #999;
.prace-wrapper{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
}
.prace{
    max-width:1200px;
    margin:0 3rem;
    display:flex;
    flex-wrap: wrap;
 
    
  flex-direction: row;
        justify-content:center;
   
 
&__header-group{

    color:#333;
    // margin:4rem 3rem 0 3rem;
    padding-left:2rem;
    max-width: 1200px;
    flex:1 0 100%;
    display: flex;
   
 
   
    @include breakpoint($md){
        margin-top:1rem;
    }
    h1{
        font-size: 1.2rem;
        line-height: 1.2em;
        margin-bottom: 0.6em;
        font-weight: 500;
    }
    p{
        color:#333; 
        font-size: 1.2rem;
    }
}    
  /* purgecss start ignore */   
&__item{
        // box-shadow: 0 0 12px rgba(0,0,0,0.2);

        // background: white;
        padding:2.2rem 2.2rem 0;
        // border:1px solid blue;
        // height:50vw;
        // max-height:500px;
        // margin:0 15px 30px 15px;
  
        width:100%;
        max-width: 500px;
        display: flex;
        @include breakpoint($sm){
            padding:3rem;
            width:50%;
            padding:1.5rem;
        }
        @include breakpoint($md){
            width:33%;
            padding:1.5rem;
        }
        @include breakpoint($lg){
            width:33%;
            padding:1.5rem;
        }
        @include breakpoint($xl){
            width:30%;
            padding:1.5rem;
           
            padding:2rem;
        }
       
        &--landscape{
            padding:2rem 0;
            img{
                // flex-grow: 0 !important;
                // height:66%;
                // margin-top: 2.1rem;
            }
        } 
      
  
        img{
           width: 100%;
            // height:88%;
            flex:1 0 auto;
            // border: 1px solid red;
            object-fit: contain;
           object-position: center;
           margin-bottom: 0.4rem;
        }
        a{
            text-decoration: none;
            color:#333;
            width:100%;
            display: flex;
            flex-direction: column;
            align-content: flex-end;
        }
        &__caption{
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            padding:0 0.8vw;

            h2{
                font-size:0.8rem;
                line-height: 1.4em;
                padding:0 0.4vw;
                font-weight: 500;
                flex:1 0 auto;
                text-align: center;
            }
            em{
                width:100%;
                font-size:0.8rem;
                padding:0 0.4vw;
                text-align: center;
            }
            span{
                flex:0 1 auto;
                font-size:0.8rem;
                text-align: center;
                margin-top:0.4em;
                small{
                    font-weight: normal;
                }
            }
            
        }
        

        &__price{
            font-size:1.78rem;
            font-weight: bold;
            text-align: right;
            width: 100%;
            display: block;
            small{
                font-size:1rem;
            }
        }
        

    }
}

  /* purgecss end ignore */