:root {

    --modal-color: #428bca;
  }


 body{
 

  
 }

  /* purgecss start ignore */

 html.person-modal--active{
    overflow: hidden;

    // cursor: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='32px' height='32px' viewBox='0 0 512 512' style='enable-background:new 0 0 512 512;' xml:space='preserve'%3E %3Cpath d='M443.6,387.1L312.4,255.4l131.5-130c5.4-5.4,5.4-14.2,0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4 L256,197.8L124.9,68.3c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4L68,105.9c-5.4,5.4-5.4,14.2,0,19.6l131.5,130L68.4,387.1 c-2.6,2.6-4.1,6.1-4.1,9.8c0,3.7,1.4,7.2,4.1,9.8l37.4,37.6c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1L256,313.1l130.7,131.1 c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1l37.4-37.6c2.6-2.6,4.1-6.1,4.1-9.8C447.7,393.2,446.2,389.7,443.6,387.1z'/%3E %3C/svg%3E"), pointer;
    // padding-right:15px;
    
  }


  .person-modal--no-active main, .person-modal--no-active .top-header{
    animation: fadeIn .2s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
  }


  .person-modal--active main, .person-modal--active .top-header{
    animation: fadeOut .2s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
   
  }


  
  .person-modal {
    pointer-events: none;
    opacity: 0;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
   
   height: 60vh;
    width: 100vw;
    overflow-y: scroll;
    display: none;
    justify-content: center;
    transition: all .3s ease;
    @include breakpoint($sm){
      align-items: center;
      height: 100vh;
    }
    // background-color: rgba(255, 255, 255, 0.2);
    // backdrop-filter: blur(0) contrast(0);
  }
  

  .person-modal.person-modal--opened{
      pointer-events:auto;
      opacity: 1;
      transition: all .1s ease;
      display: flex;
    }

 

  .person-modal__content {
    margin:20px 20px 40px 20px;
    height: 100%;
    max-width: 1024px;
    background: #fff;
    // box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 7px 20px 0 rgba(0, 0, 0, 0.17);
    
    display: flex;
    flex-direction: column;
    padding:2rem;
    @include breakpoint(medium){
      margin: 10% auto;
      flex-direction: row;
      width: 90%;
    }
  
  }
  

  .person-modal--opened .person-modal__content {
    animation: zoomIn .3s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
  }

  .person-modal--closed .person-modal__content {
    animation: zoomOut .1s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
  }


  @keyframes zoomIn {
    0% {
      opacity: 0;
      transform:scale(0.8);
     
    }
    50%{
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform:scale(1); 
    }
  }

  @keyframes zoomOut {
    0% {
      opacity: 1;
      transform:scale(1);
     
    }
 
    100% {
      opacity: 0;
      transform:scale(0.9) ; 
    }
  }

  @keyframes fadeOut{
    0% {
      transform: scale(1) rotateZ(0);
      filter: blur(0px) brightness(100%) opacity(100%);
    }
    100% {
      transform:scale(0.95) rotateZ(0);
      filter: blur(5px) brightness(120%) opacity(70%);
    }
  }




  @keyframes fadeIn{
    0% {
      transform: scale(0.95) rotateZ(0);
      filter: blur(5px);
    }
    100% {
      transform:scale(1) rotateZ(0);
      filter: blur(0px);
    }
  }




    /* purgecss end ignore */


  .person-modal__close{
      position: fixed;
      cursor: pointer;
      z-index:111;
      right:10px;
      top:10px;
      width:2rem;
      height:2rem;
      background:no-repeat center url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyOC4zNSAyOC4zNSI+CiAgPHBvbHlnb24gc3R5bGU9IiMzMzMiIHBvaW50cz0iMjQuOTEgNC45MyAyMy40MSAzLjQ0IDE0LjE3IDEyLjY4IDQuOTMgMy40NCAzLjQ0IDQuOTMgMTIuNjggMTQuMTcgMy41MyAyMy4zMyAzLjQ0IDIzLjQyIDQuOTMgMjQuOTEgMTQuMTcgMTUuNjYgMjMuMzMgMjQuODIgMjMuNDEgMjQuOTEgMjQuOTEgMjMuNDIgMTUuNjYgMTQuMTcgMjQuOTEgNC45MyIvPgo8L3N2Zz4K");
      @include breakpoint(medium){
        // display:none;
      }
  }
  

  .person-modal__image-box {
    width: 100%;
      margin-right:2rem;
      @include breakpoint(medium){
        width: 30%;
      }
  }

  .person-modal__name{
     
      margin-top:1.5rem;
      font-size: 1.8rem;
      margin-bottom:1.5rem;
      @include breakpoint(medium){
         font-size: 1.1rem;
         margin-bottom:0;
      }
      small{
        font-size: 0.65em;
        color: #999;
        display: block;
      }

  }
  
  .person-modal__bio {
    width: 90%;
    font-size: 1.3em;
    @include breakpoint(medium){
      width: 60%;
      font-size: 1rem;
    }
    // margin-top:2rem;
    // margin-bottom:2rem;
  }


  


  // .person-modal:hover,
  // .person-modal:focus {
  //   color: #000;
  //   text-decoration: none;

  // }
  
