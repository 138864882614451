$gray-text: #999;

.projekty{
padding-top:6rem;

   

    &__header{
        font-size: 2.4rem;
        line-height:1.2em;
        //margin-bottom:3rem;
        margin-top:5rem;
        @include breakpoint($md){
            font-size: 2.1rem;
            margin-top:0;
            font-size: 2.6rem;
        //     margin-bottom:4rem;
          }
        span{
            color:$secondary-color;
        }
         
    }
    &__digit--big{
        h2{
            color: $secondary-color;
            margin-bottom:0;
            line-height: 1;
            font-size: 7rem;
            font-size: 5rem;
            @include breakpoint($md){
                font-size: 6rem;
            }
            @include breakpoint($lg){
                font-size: 7rem;
            }
        }
        p{
            font-size: 3.5rem;
            line-height:1.15em;
            @include breakpoint($md){
                font-size: 3.5rem;
                line-height:1.1em;
            }
            @include breakpoint($md){
                font-size: 3.1rem;
            }
        }
    }
    &__digit{
        h2{
            color: $secondary-color;
            margin-bottom:0;
            line-height: 1;
            font-size: 7rem;
        }
        p{
            font-size: 2.5rem;
            line-height:1.15em;
            @include breakpoint($md){
                font-size: 2.1rem;
                line-height:1.15em;
            }
            @include breakpoint($lg){
                font-size: 2.6rem;
               
            }
        }
    }

    &__item{
      
        position:relative;
        margin-bottom:5rem;
            &:hover{
                cursor: pointer;
                .projekty__item__header{
                    color:$secondary-color;
                    cursor: pointer;
                    
                }
                .projekty__item__body{
                    //  color:$secondary-color;
                }
                .projekty__item__img{
                    transform: scale(1.012);
                    transition: transform cubic-bezier(0.22, 1, 0.36, 1) 1s ;
                    cursor: pointer;
                }
                .projekty__item__tag{
                    // color:$secondary-color;
                }
            }
            &__img{
                transition: transform cubic-bezier(0.22, 1, 0.36, 1) 0.4s;
                margin-bottom: 2rem;
            }

            &__header{
                font-size:1.7rem;
                margin-bottom: 0.3em;
                line-height: 1.2;
                transition: color ease 0.4s ;
                @include breakpoint($md){
                  font-size:1.8rem;
                  line-height: 1.1;
                }
                @include breakpoint($lg){
                  font-size:1.5rem;
                  margin-bottom: 0.4em;
                }
              }

              &__body{
                font-size:1.2rem;
                line-height: 1.5;
                margin-bottom:2rem;
                transition: color cubic-bezier(0.22, 1, 0.36, 1) 0.4s ;
                @include breakpoint($md){
                  font-size:1rem;
                  line-height: 1.4;
                }
                @include breakpoint($lg){
                  font-size:0.9rem;
                  margin-right:2rem;
                }
              }

            &__tags{
                font-size:1rem;
                margin-bottom:0.3em;
                color: $gray-text;
                margin-bottom:0.7rem;
                transition: color cubic-bezier(0.22, 1, 0.36, 1) 0.4s ;
                 @include breakpoint($lg){
                  font-size:0.75rem;
                }
              }
        
        &--other{
            margin-bottom:2rem;
            .projekty__item__header{
                font-size:1.2rem;
            }
        }
    }
    &__item--square{
        position:relative;
        margin-bottom:5rem;
       
        &:hover{
            cursor: pointer;
            .projekty__item--square__body h2{
                color:$secondary-color;
                cursor: pointer;
                
            }
            .projekty__item--square__body{
                //  color:$secondary-color;
            }
            .projekty__item--square figure img{
                transform: scale(1.012);
                transition: transform cubic-bezier(0.22, 1, 0.36, 1) 1s ;;
                cursor: pointer;
            }
            .projekty__item--square__tag{
                // color:$secondary-color;
            }
        }

        figure img{
            width: 60vw;
            position: absolute;
            right:0;
            top:0;
            transition: transform cubic-bezier(0.22, 1, 0.36, 1) 0.4s;
            @include breakpoint($md){
                width: 30vw;
            }
            @include breakpoint($lg){
                width: 20vw;
                max-width:380px;
            }
        }
       
        
        &__body{
            
                

                h3{
                    font-size: 1rem;
                    margin-top:62vw;
                    margin-bottom:0.6rem;
                    @include breakpoint($md){
                       margin-top: 28vw;
                    }
                    @include breakpoint($lg){
                        
                        margin-top: 18vw;
                    }
                }
                h2{
                    font-size: 3.1rem;
                    line-height: 1.1em;
                    margin-bottom:0.5rem;
                    transition: color cubic-bezier(0.22, 1, 0.36, 1) 0.4s ;
                    @include breakpoint($md){
                        margin-bottom:0.8rem;
                        
                    }
                    @include breakpoint($lg){
                        font-size: 3.5rem;
                    }
                }
                p{
                    font-size:1.4rem;
                    margin-bottom:2rem;
                    line-height:1.4;
                    @include breakpoint($md){
                        font-size:1.3rem;
                    }
                }
            }
            &-no-hover{
                &:hover{
                    cursor: initial;
                    .projekty__item--square__body h2{
                        color:$primary-color;
                        cursor:initial;
                        
                    }
                    .projekty__item--square figure img{
                        transform: none;
                        transition:none;
                        cursor: initial;
                    }
                }
            }
    }
    &__item--number{

        margin-top:10.5vw;
    }

}



