
.about{
padding-top:6rem;
   
    &__header{
        font-size: 2.4rem;
        line-height:1.2em;
        //margin-bottom:3rem;
        margin-top:5rem;
        @include breakpoint($md){
            font-size: 2.1rem;
            margin-top:0;
            font-size: 2.6rem;
        //     margin-bottom:4rem;
          }
        font-size: 2.1rem;
        line-height:1.4em;
        margin-top:-5.5rem;
        z-index: 1;
        position: relative;
        span{
            color:$secondary-color;
        }
       
         
    }

    &__header-image{
       
        transform-origin: bottom right;
        @include breakpoint($md){
           
          }
        @include breakpoint($lg){
            transform:scale(1.7);
            margin-top:4rem;
          }
          @include breakpoint($xl){
            transform:scale(1.65);
          }
        
    }

    &__body{
        font-size: 1.3rem;
        line-height: 1.4;
        position: relative;
        z-index: 1;
        margin-bottom:1.5em;
        @include breakpoint($md){
            line-height: 1.5;
            font-size: 1.2rem;
            margin-bottom:1.3em;
        }
        @include breakpoint($lg){
            font-size: 1.1rem;
        }
        @include breakpoint($xl){
            font-size: 1.1rem;
        }
    }

    &__body-image{

    }

    &__person{
        cursor: pointer;
       
        &__header{
            font-size:3rem;
        }
        &__image{
            margin-bottom:0.9rem;
            margin-bottom:1rem;
        }
        &__image-caption{
            transition: color 300ms ease;
            font-size:1.2rem;
            line-height: 1.2;
            margin-bottom:1rem;
            @include breakpoint($md){
                font-size:1.2rem;
            }
            @include breakpoint($lg){
                font-size: 1.1rem;
            }
        }
        small{
            display: block;
            color:$gray-text;
            font-size: 0.85em;
            margin-top:0.5em;
            @include breakpoint($md){
                font-size: 0.65em;
            }
        }
        &__image{
            width:90%;
            @include breakpoint($md){
                font-size: 1.1rem;
                width:80%;
            }
            @include breakpoint($lg){
                font-size: 1.1rem;
                width:80%;
            }
            @include breakpoint($xl){
                font-size: 1.1rem;
                width:80%;
            }

        }
        &__bio{
            display: none;
        }
        &:hover{
            color: $secondary-color;
        }
    }
    
    &__join-header{
        font-size:2.8rem;
        color: $secondary-color;
        line-height: 1.3;
        @include breakpoint($md){
            font-size:3.4rem;
            font-weight:300;
        }
    }
   
}
    