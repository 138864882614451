
.case{

    padding-top:70px;
    @include breakpoint($lg){
        padding-top:0;
    }
    &__header-wrapper{
      
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        height:70vw;
        max-width: 1800px;
        max-height:500px;
        margin:0 auto;
        background:#555;
        @include breakpoint($md){
            height:35vw;
        }
    }

    &__header-img{
        position: absolute;
        object-fit:cover;
        width: 100%;
        height: 100%;
           

    }
    &__header{
        font-size: 2.5rem;
        margin-bottom:0.7rem;
        line-height: 1.1;
        position: relative;
        z-index: 11;
        color:$white;
//         text-shadow: 0 0 30px rgba(0,0,0,0.4);
        @include breakpoint($md){
            margin-bottom:2rem;
            font-size: 2.8rem;
        }
        @include breakpoint($lg){
            font-size: 3.2rem;
        }
        &--black{
            color:$black;
            text-shadow:none;
//             text-shadow: 0 0 30px rgba(255,255,255,0.8);
        }
    }
    &__header-small{
        font-size: 1.1rem;
        position: relative;
        z-index: 11;
        color:$white;
       
        @include breakpoint($md){
            font-size: 1.4rem;
        }
        &--black{
            color:$black;
            text-shadow:none;
           
            text-shadow: 0 0 30px rgba(255,255,255,0.8);

             @include breakpoint($md){
                 margin-top: -10rem;
             }
        }
    }

    &__lead{
        font-size: 1.8rem;
        margin-bottom:5rem;
        line-height: 1.5;
        position: relative;
        margin-top:3.5rem;
     
        @include breakpoint($md){
            font-size: 1.6rem;
        }
        @include breakpoint($lg){
            font-size: 1.6rem;
        }
    }
    &__body-header{
        font-size: 1.8rem;
        margin-bottom:0.8em;
        line-height: 1.1;
        @include breakpoint($md){
            font-size: 1.8rem;
        }
        @include breakpoint($lg){
            font-size: 1.6rem;
        }
    }
    &__body{
        font-size: 1.3rem;
        margin-bottom: 2rem;
        line-height: 1.6;
        @include breakpoint($md){
            font-size: 1.2rem;
        }
        @include breakpoint($lg){
            font-size: 0.95rem;
        }
    }
 
    &__big-claim{
        font-size: 2.8rem;
        margin-bottom:0.8em;
        line-height: 1.2;
        font-weight:300;
        color:$secondary-color;
        @include breakpoint($md){
            font-size: 3.2rem;
        }
        @include breakpoint($lg){
            font-size: 3.7rem;
        }
    }

    &__small-claim{
        font-size: 1.8rem;
        margin-bottom:0.8em;
        line-height: 1.2;
        font-weight:300;
        color:$secondary-color;
        @include breakpoint($md){
            font-size: 1.5rem;
        }
        @include breakpoint($lg){
            font-size: 1.8rem;
        }
    }
    &__credits{
        // white-space: pre-line;
        h3{
            margin-bottom:1rem;
            text-transform: uppercase;
            letter-spacing: 0.2em;
            font-size:0.8rem;
        }
        h4{
            margin-top:0.6rem;
        }
       
    }
    &__video{
        max-height:98vh;
    }
}

 /* purgecss start ignore */
.black-mode{
    background:#101010;
    .case__lead{
        color:white;
    }
    .case__body{
        color:white;
    }
    .case__body-header{
        color:white;
    }
    .nav__menu > li > a{
        color:white;
    }
    .projekty__item__header{
        color:white;
    }
    .footer__address{
        color:white;
    }
    .st0{
        fill:white !important;
        }
 

}

/* purgecss end ignore */
