
* {
    box-sizing: border-box;
}

html, body{
    margin:0;
    font-family: $font-1;
    font-weight: 500;
   
    display: flex;
    flex-direction: column;

    min-height: 100vh;
}


p,span,a,small,ul,ol, blockquote,address,
h1,
h2,
h3,
h4,
h5,
h6 {
-webkit-font-smoothing: antialiased;
line-height: 1.5;
}

//breakpoints as tilewind
//bez prefixu: 0-640
// $sm: 640px;
// $md: 768px;
// $lg: 1024px;
// $xl: 1280px;



//breakpoints bootstrap
//bez prefixu: 0-576
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;








html {
    // font-size: clamp(16px, 0.2rem + 0.8vw, 46px);
    font-size: clamp(16px, 0.8523rem + 0.4545vw, 19px);
    // https://clamp.font-size.app/
    color:$primary-color;
    
  }


//   html {
//     font-size: 12px;
//     color:$primary-color;
//     @include breakpoint($sm){
//         font-size: 13px;

       
//     }
//     @include breakpoint($md){
//         font-size: 14px;

//     }
//     @include breakpoint($lg){
//         font-size: 15px;
//     }
//     @include breakpoint($xl){
//         font-size: 17px;
//     }
//   }


  //reset 
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed, 
  figure, figcaption, footer, header, hgroup, 
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
      margin: 0;
      padding: 0;
      border: 0;
      font-family: $font-1;
      vertical-align: baseline;
  }

  button{
    font-family: $font-1;
  }

/* HELPER */

/* purgecss start ignore */

  body{
    // box-shadow: inset 0px 0px 5px 0px yellow;
  
    .czujnik::after{
        content: "initial";
        font-size: 12px;
        display: inline;
        margin-left:10px;
    }
  
   
    @include breakpoint($sm){
        // box-shadow: inset 0px 0px 5px 0px  orange;
        .czujnik::after{
            content: "sm";
        }
    }
    @include breakpoint($md){
        // box-shadow: inset 0px 0px 5px 0px  red;
        .czujnik::after{
            content: "md";
        }
       
    }
    @include breakpoint($lg){
        // box-shadow: inset 0px 0px 5px 0px  purple;
        .czujnik::after{
            content: "lg";
        }
        
    }
    @include breakpoint($xl){
        // box-shadow: inset 0px 0px 5px 0px blue;
        .czujnik::after{
            content: "xl";
        }
        
    }
  }
 /* purgecss end ignore */


//  html,body{
//     /*scrollr*/
//     height: 100% !important; 

//  }

main{
    margin-top:30px;
    flex: 1 1 auto;
}

main img{
    max-width: 100%;
}


span a, p a, address a, small a, li a{
    color:$secondary-color;
    text-decoration: none;
}

span a:hover, p a:hover, address a:hover, small a:hover, li a:hover{
    color:$secondary-color;
    border-bottom: 1px $secondary-color solid;
}


// .home-feed__body--news, .home-feed__body{
//     text-align: justify;
//     hyphens: auto;

// }



