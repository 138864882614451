.praca-wrapper{
    display:flex;
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content:center;
    width:100vw;
    padding-bottom: 2rem;
}



  

.praca{
    // box-shadow: 0 0 12px rgba(0,0,0,0.2);
    border-radius:10px;
    // background: white;

   
    // margin:1rem 2rem 0 2rem;

    display: flex;
    flex-direction: column;
    flex-wrap:wrap;
    align-items: flex-start;
    position: relative;

    @include breakpoint($md){
        padding:0;
        padding: 1rem;
        flex-direction: row;
        max-width:680px;
    }
    @include breakpoint($lg){

        flex-direction: row;
        max-width:1070px;
    }
    &__back-link{
        width: 100%;
        max-width:380px;
        font-size:0.9rem;
        margin-bottom:1rem;
        flex:1 0 100%;
        font-weight: normal;
        padding-left:5vw;
        @include breakpoint($md){
            padding-left:0;
            max-width:initial;
        }
        a{
            color:#333;
            text-decoration: none;
        } 
    }
    &__img-box{
        width:100%;
        margin-bottom:0.5rem;
        transition: ease 200ms all;
        width: 100vw;
       
        overflow-x: hidden;
       
        @include breakpoint($md){
            width:45%;
            margin-right:2%;
        }
    }
     &__img-slide{
        width:auto;
        margin-bottom:2rem;
        margin-left:1rem;
        margin-right:1rem;
     }  
     &__img-slide:nth-child(1){
        width:auto;
        margin-bottom:2rem;
        margin-left:0;
        margin-right:0;
        
     }   

    &__img{
        max-width:70vw;
        margin-left:4vw;
        margin-right:0;
        @include breakpoint($md){
            width:100%;
            margin-left:0;
        }
    }
   
    &__img-wrapper{
      
        @include breakpoint($md){
          
           
            flex-direction: column;
        }
        
    }
    &__title-box{
    
        border-bottom:40px;
        padding: 1rem 3vw;
        margin-left:5vw;
        transition: ease 200ms all;
        // width: 100%;
        max-width:330px;
        @include breakpoint($md){
            width:53%;
            
         
            padding: 0;
            margin-left:0;
        }
    }
     &__title-box-fixed{
        @include breakpoint($md){
            position: fixed;
            margin-bottom:4rem;
        }
     }
    &__artist{
        font-size: 1.4rem;
        transition: ease 200ms all;
        font-weight: 500;
    }
    &__title{
        font-size: 1.1rem;
        font-weight: 400;
        font-style: italic;
        margin-bottom:1.2rem;
        transition: ease 200ms all;
    }

    &__year{
        font-size: 0.7rem;
       margin-left:0.2rem;
        font-style: normal;
        font-weight: 400;
        transition: ease 200ms all;
        
    }
    &__desc{
        font-size: 0.9rem;  
        margin-bottom:1.5rem;
        font-weight: 400;
        transition: ease 200ms all;
        max-width:330px;
        @include breakpoint($md){
            max-width:486px;
        }
    }

    &__desc--buy{
   
        display: none;
    }

    &__price{
        font-size:1.3rem;
        margin-right:1.5rem;
        transition: ease 200ms all;
    }




    &__buy-box{
        display: flex;
        flex-direction: row;
        align-items: center;
       
        transition: ease 200ms all;
    }

    &__button{
        background: transparent;
        color:#333;
        font-size: 0.9rem;
        padding:1.2rem 2rem;
        border-radius: 0.3rem;
        border:1.5px solid #333;
        letter-spacing: 0.08em;
        font-weight: 500;
        display: inline-block;
        text-align:center;
        text-decoration: none;
        flex-grow: 1;
        cursor: pointer;
        &:hover{
            letter-spacing: 0.1em;
        }
        @include breakpoint($md){
            flex-grow: 0;
            padding:1.2rem 3rem;
        }
    }
   
  
  
    h1{
        font-size:1.4rem;
        line-height: 1.4em;
    }
    &__price{
        font-size:1.4rem;
        font-weight: bold;
    }



}
   /* purgecss start ignore */

   .kupowanie {

  
    flex-direction: row;
    max-width:380px;
   }

.kupowanie .praca__img-box{
    width:36%;
    margin-bottom:0;
    margin-right: 0.7rem;
}
.kupowanie.praca__title-box {
    margin-left: 0;
}
.kupowanie .praca__img{
    width:100%;
    margin:0;
    
}
.kupowanie .praca__title-box{

        margin-top:0;

 }
.kupowanie .praca__title-box-fixed{
       position: static;
        margin-bottom:0;

 }

.kupowanie .praca__img-slide:nth-child(1){
    width:100%;
    margin:0;
    display: block;
  
  
}
.kupowanie .praca__img-slide {
    width: 100%;
    display: none;
 
}
.kupowanie .praca__title-box{
    width:55%;
    // position: absolute;
    margin-bottom:0;
   

}

.kupowanie .praca__desc--buy{
   display: block;
   font-size: 0.9rem;  
   margin-bottom:1rem;
   font-weight: 400;
}

.kupowanie .praca__desc{
    display: none;
 }

.kupowanie  .praca__artist{
    font-size: 1rem;
    
}

.kupowanie  .praca__title{
    font-size: 0.91rem;
   margin-bottom: 0.4rem;
}

.kupowanie  .praca__price{
    font-size: 1rem;
   
}

.praca__form-wrapper{
    box-shadow: 0 0 12px rgba(0,0,0,0.2);
    border-radius:10px;
    background: white;
    padding:20px;
    margin:1rem 2rem 2rem 2rem;
    max-width:380px;
    display: none;
    flex-direction: row;
    flex-wrap:wrap;
    align-items: flex-start;
}




   /* purgecss end ignore */